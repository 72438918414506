import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import login from '../views/login'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    id: 1, // 与后端一级菜单id对应
    path: '/',
    component: Layout,
    redirect: '/Custom',
    children: [
      {
        path: 'Custom',
        name: 'Custom',
        component: () => import('@/views/custom/index'),
        meta: { title: 'Custom' }
      }
    ]
  },
  {
    id: 2, // 与后端一级菜单id对应
    path: '/',
    component: Layout,
    redirect: '/Warning',
    children: [
      {
        path: 'Warning',
        name: 'Warning',
        component: () => import('@/views/warning/index'),
        meta: { title: 'Warning' }
      }
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
