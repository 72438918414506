const enLang = {
  Basic: {
    Temp: 'Temp'
  },
  Machine: {
    Equipment: 'Equipment',
    MyEquipment: 'Equipment',
    Group: 'Group',
    Alarm: 'Alarm',
    Setting: 'Setting',
    Workshop: 'Workshop',
    EquipmentGroup: 'Equipment Group',
    Search: 'Search',
    All: 'All',
    net: 'Networking',
    notNet: 'Not Networked',
    Edit: 'Edit',
    Done: 'Done',
    Add: 'Add',
    Run: 'Run',
    Standby: 'Standby',
    Soft: 'Soft Start',
    Warning: 'Warning',
    Auto: 'Auto Tuning',
    Manual: 'Manual',
    Test: 'Test T/C',
    Stop: 'Stop',
    Name: 'Name',
    Model: 'Model',
    Serial: 'Serial Number',
    EquipmentName: 'Please input the equipment name'
  },
  AddMachine: {
    GroupName: 'Group Name',
    GroupSimple: 'Group',
    SerireSimple: 'Series',
    AddEquipment: 'Add Equipment',
    NoPower: 'No Permission',
    Parameter: 'Parameter Setting',
    InjectionWorkshop: 'Injection Workshop',
    EquipmentName: 'Equipment Name',
    EquipmentModel: 'Equipment Model',
    EquipmentSerialNumber: 'Equipment Serial Number',
    Enable: 'Enable',
    Image: 'Image',
    Operation: 'Operation',
    Cancel: 'Cancel',
    selectEquipment: 'Please select a equipment',
    inputSerialNumber: 'Please input the serial number'
  },
  TempTab: {
    EquipmentMatchingCode: 'Equipment Matching Code',
    Temp: 'Temp',
    Timer: 'Timer',
    SPM: 'SPM ',
    RemoteOperationRecord: 'Remote Operation Record'
  },
  Temp: {
    matchingCode: 'Please input the equipment matching code',
    Target: 'Target',
    ControlSystem: 'Temperature Control Box',
    All: 'Select All',
    Control: 'Remote Control',
    Cancel: 'Deselect',
    Apply: 'Apply'
  },
  Timer: {
    Item: 'Item',
    CH: 'CH.',
    Model: 'Model',
    FirstInjection: '1st Injection',
    SecondInjection: '2nd Injection',
    Delay1: 'Delay1',
    Delay2: 'Delay2',
    Open1: 'Open1',
    Open2: 'Open2',
    Close1: 'Close1',
    Close2: 'Close2'
  },
  Record: {
    ZoneNumber: 'Zone Number',
    Temperature: 'Temperature',
    Switch: 'Switch',
    Modifier: 'Modifier',
    ModificationTime: 'ModificationTime'
  },
  Login: {
    Account: 'Account',
    Password: 'Password',
    SignIn: 'Sign In',
    ForgotPassword: 'Forgot Password',
    Language: 'Language',
    Email: 'E-mail',
    MobilePhone: 'Mobile Phone',
    VerificationCode: 'Verification Code',
    ResetPassword: 'Reset Password',
    ConfirmPassword: 'Confirm Password',
    SendVerificationCode: 'Send Verification Code',
    Backtologinpage: 'Back to login page',
    OK: 'OK',
    phoneNumber: 'Please input your mobile phone number',
    verificationCode: 'Please input the verification code',
    resetThePassword: 'Please reset the password',
    newpassword: 'Please confirm the new password',
    inputPwd: 'Please input the user password'
  },
  UserInfo: {
    PersonalInformation: 'Personal Information',
    SuperAdministrator: 'Super Administrator',
    Administrators: 'Administrators',
    User: 'User',
    Users: 'User',
    UserName: 'User Name',
    Workshop: 'Workshop',
    CurrentPassword: 'Current Password',
    NewPassword: 'New Password',
    Account: 'Account',
    ChangePassword: 'Change Password'
  },
  Warning: {
    StartTime: 'Start Time',
    EndTime: 'End Time',
    ErrorTime: 'Error Time',
    Error: 'Error',
    NoData: 'No Data',
    inputName: 'Please input a name',
    selectTime: 'Please select the start time',
    selectEndTime: 'Please select the end time',
    ActualTemperature: 'Actual Temperature',
    SettedTemperature: 'Setted Temperature',
    Status: 'Status',
    Output: 'Output',
    Current: 'Current',
    TemperatureHigh: 'Temperature High',
    TemperatureLow: 'Temperature Low',
    Reset: 'Reset'
  },
  Setting: {
    User: 'User',
    DefaultWorkshop: 'Default Workshop',
    CreationTime: 'Creation Time',
    ModificationTime: 'Modification Time',
    EditWorkshop: 'Edit Workshop',
    AddWorkshop: 'Add Workshop',
    defaultDeleted: 'The default workshop cannot be deleted',
    exists: 'Equipment exists in the current workshop and cannot be deleted',
    equipmentGroup: 'Please select equipment group',
    WorkshopInput: 'Please select a workshop',
    WorkShopIp: 'Please input a workshop',
    GroupQuantity: 'Group Quantity',
    GroupName: 'Group Name',
    addGroup: 'Add equipment group',
    editGroup: 'Edit equipment group',
    DefaultGroup: 'Default equipment group',
    equipmentDeleted: 'The default equipment group cannot be deleted',
    cannotDeleted: 'Equipment exists in the current group and cannot be deleted',
    EquipmentType: 'Equipment Type',
    SerialNumber: 'Serial Number',
    AddEquipment: 'Add Equipment',
    MatchingCode: 'Matching Code',
    ChangeCode: 'Change code',
    EditEquipment: 'Edit Equipment',
    ErrEmail: 'Email format error',
    UploadImage: 'Upload Image',
    InputModel: 'Please input the model',
    serialNumber: 'Please input the serial number',
    RemovedSuccessfully: 'Removed Successfully',
    PhoneNumber: 'Phone Number',
    AddUser: 'Add User',
    EditUser: 'Edit User',
    Account: 'Account',
    Role: 'Role',
    Level: 'Level',
    IptAccout: 'Please input the account name',
    CopyCode: 'Copy Code',
    PowerControl: 'Permission Control',
    Control: 'Remote Control',
    OnlyCheck: 'Only View',
    IptEmail: 'Please input a email',
    IpUserName: 'Please input user name',
    oldPwd: 'Please input current password',
    Serial: 'Serirs',
    IpPwd: 'Please input a password',
    newPwd: 'Please input new password',
    IptRole: 'Please select roles',
    Biggest: 'View maximized picture'
  },
  Tip: {
    imageLimit: 'Uploaded images can only be in JPG, JPEG and PNG formats',
    CopySuccessful: 'Copy Successful',
    CopyFailed: 'Copy Failed',
    Blank: 'Account or password cannot be blank',
    Modified: 'Password modified successfully',
    Digits: 'Password length cannot be less than 6 digits',
    Inconsistent: 'The reset password and confirm password are inconsistent',
    equipmentExceed: 'The number of words of equipment name cannot exceed 20',
    modelExceed: 'The number of words of model name cannot exceed 20',
    Format: 'The phone number format input error',
    IncorrectEmail: 'Incorrect email input',
    DeleteEquipment: 'Are you sure you want to delete the equipment',
    WorkshopExceed: 'The number of words of workshop name cannot exceed 20',
    Criteria: 'Please input query criteria',
    GroupExceed: 'The number of words of device group name cannot exceed 20',
    DeleteGroup: 'Are you sure you want to delete the equipment group',
    DeleteWorkshop: 'Are you sure you want to delete the workshop',
    DeleteUser: 'Are you sure you want to delete the user',
    UserExceed: 'The number of words in the user name cannot exceed 20',
    AccountExceed: 'The number of words in the account name cannot exceed 20',
    SelectMachine: 'Please select the device to be turned on',
    AddSuccess: 'Add Successfully',
    EditSuccess: 'Edit Successfully',
    Tips: 'Tip',
    Loading: 'Loading...',
    token: 'The token has expired',

    ALHigh: 'AL-High',
    ALLow: 'AL-Low',
    ThermocoupleOpen: 'Thermocouple Open',
    ThermocoupleReverse: 'Thermocouple Reverse',
    ThermocoupleShort: 'Thermocouple Short',
    HeaterOpen: 'Heater Open',
    HeaterShort: 'Heater Short',
    TriacBreakdown: 'Triac Breakdown',
    FuseOpen: 'Fuse Open',
    LeakageCurrent: 'Leakage Current',
    Mixed: 'Mixed connection heater and T/C wire.',
    Default: 'Default Workshop',
    overDate: 'The start time cannot be greater than the end time'
  },
  Custom: {
    Custom: 'Custom',
    CustomName: 'Customer Company Name',
    CompanyName: 'Please input company name',
    Account: 'Please input the account number',
    Company: 'Company',
    Email: 'Company Email',
    Order: 'Order',
    AddSerial: 'Add Serial Number',
    Serial: 'Serial',
    AddCompany: 'Add Company',
    EditCompany: 'Edit Company',
    DeleteSerial: 'Are you sure you want to delete the serial number?',
    AccountCn: 'Account name cannot contain Chinese characters',
    overCompany: 'The number of words of the company name cannot exceed 20',
    SerialIpt: 'Please input the serial number',
    Pwd: 'Please input the password',
    AdPwd: 'Administrator password',
    AdPower: 'Administrator privileges',
    Number: 'Equipment Number',
    OutSuccess: 'Withdrawal succeeded',
    InSuccess: 'Transfer succeeded',
    CustomSelect: 'Customer selection',
    Background: 'Manage background',
    SelectCustom: 'Please select a customer',
    NotMachine: 'Not transferred equipment'
  }
}

module.exports = enLang
