import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/index.less' // global css
import i18n from '@/lang'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueClipboard from 'vue-clipboard2'
import '../public/icon/iconfont.css'
import md5 from 'js-md5'
Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.prototype.$md5 = md5
Vue.use(ElementUI)
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
