const zhLang = {
  Basic: {
    Temp: 'Temp'
  },
  Machine: {
    Equipment: '设备',
    MyEquipment: '我的设备',
    Alarm: '报警信息',
    Setting: '设置',
    Group: '设备组',
    Workshop: '车间',
    EquipmentGroup: '设备组',
    net: '联网',
    notNet: '未联网',
    Search: '搜索',
    All: '全部',
    Edit: '编辑',
    Done: '完成',
    Add: '新增',
    Run: '运行',
    Standby: '待机',
    Warning: '报警',
    Soft: '软启动',
    Auto: '自动调整',
    Manual: '手动',
    Test: '测温',
    Stop: '停机',
    Name: '名称',
    Model: '型号',
    Serial: '序列号',
    EquipmentName: '请输入设备名称'
  },
  AddMachine: {
    GroupName: '组名',
    GroupSimple: '设备组',
    AddEquipment: '新增设备',
    SerireSimple: '序列号',
    InjectionWorkshop: '注塑车间',
    NoPower: '暂无权限控制',
    Parameter: '参数设置',
    EquipmentName: '设备名称',
    EquipmentModel: '设备型号',
    EquipmentSerialNumber: '设备序列号',
    Enable: '启用',
    Image: '图片',
    Operation: '操作',
    Cancel: '取消',
    selectEquipment: '请选择设备',
    inputSerialNumber: '请输入序列号'
  },
  TempTab: {
    EquipmentMatchingCode: '设备匹配码',
    Temp: '温度控制',
    Timer: '时序',
    SPM: '位移',
    RemoteOperationRecord: '远程操作记录'
  },
  Temp: {
    matchingCode: '请输入设备匹配码',
    Target: '目标',
    ControlSystem: '温控箱',
    All: '全选',
    Cancel: '取消选择',
    Control: '远程控制',
    Apply: '开启'
  },
  Timer: {
    Item: '项目',
    CH: '编号',
    Model: '模式',
    FirstInjection: '第一段注塑',
    SecondInjection: '第二段注塑',
    Delay1: '延迟1',
    Delay2: '延迟2',
    Open1: '打开1',
    Open2: '打开2',
    Close1: '关闭1',
    Close2: '关闭2'
  },
  Record: {
    ZoneNumber: '组编号',
    Temperature: '温度',
    Switch: '开关',
    Modifier: '修改者',
    ModificationTime: '修改时间'
  },
  Login: {
    Account: '账号',
    Password: '密码',
    SignIn: '登录',
    ForgotPassword: '忘记密码',
    Language: '语言',
    Email: '邮箱',
    MobilePhone: '手机号码',
    VerificationCode: '验证码',
    ResetPassword: '重设密码',
    ConfirmPassword: '确认密码',
    SendVerificationCode: '发送验证码',
    Backtologinpage: '返回登录页',
    OK: '确定',
    phoneNumber: '请输入手机号码',
    verificationCode: '请输入验证码',
    resetThePassword: '请输入重设密码',
    newpassword: '请确认新密码',
    inputPwd: '请输入用户密码'
  },
  UserInfo: {
    PersonalInformation: '个人信息',
    SuperAdministrator: '超级管理员',
    Administrators: '管理员',
    User: '普通用户',
    Users: '用户',
    UserName: '用户名称',
    Workshop: '所属车间',
    CurrentPassword: '当前密码',
    Account: '账户',
    NewPassword: '新密码',
    ChangePassword: '修改密码'
  },
  Warning: {
    StartTime: '开始时间',
    EndTime: '结束时间',
    ErrorTime: '错误时间',
    Error: '错误',
    NoData: '暂无数据',
    inputName: '请输入名称',
    selectTime: '请选择开始时间',
    selectEndTime: '请选择结束时间',
    ActualTemperature: '实际温度',
    SettedTemperature: '设定温度',
    Status: '状态',
    Output: '输出功率',
    Current: '电流',
    TemperatureHigh: '温度上限',
    TemperatureLow: '温度下限',
    Reset: '重置'
  },
  Setting: {
    User: '用户',
    DefaultWorkshop: '默认车间',
    CreationTime: '创建时间',
    ModificationTime: '修改时间',
    EditWorkshop: '修改车间',
    AddWorkshop: '新增车间',
    defaultDeleted: '默认车间无法删除',
    exists: '当前车间存在设备，不可删除',
    equipmentGroup: '请选择设备组',
    WorkshopInput: '请选择车间',
    WorkShopIp: '请输入车间名称',
    GroupQuantity: '设备组数量',
    GroupName: '设备组名称',
    addGroup: '新增设备组',
    editGroup: '编辑设备组',
    DefaultGroup: '默认设备组',
    equipmentDeleted: '默认设备组无法删除',
    cannotDeleted: '当前设备组存在设备，不可删除',
    EquipmentType: '设备类型',
    SerialNumber: '序列号',
    AddEquipment: '新增设备',
    CopyCode: '复制匹配码',
    MatchingCode: '匹配码',
    ChangeCode: '更换匹配码',
    EditEquipment: '编辑设备',
    UploadImage: '上传图像',
    InputModel: '请输入型号',
    serialNumber: '请输入序列号',
    RemovedSuccessfully: '删除成功',

    PhoneNumber: '手机号码',
    AddUser: '新增用户',
    EditUser: '编辑用户',
    IptAccout: '请输入账户名',
    Account: '账户',
    Role: '角色',
    Level: '等级',
    PowerControl: '权限控制',
    IpUserName: '请输入用户名',
    ErrEmail: '邮箱格式错误',
    IptEmail: '请输入邮箱',
    Control: '可远程控制',
    OnlyCheck: '仅查看',
    IpPwd: '请输入密码',
    oldPwd: '请输入当前密码',
    newPwd: '请输入新密码',
    Serial: '序列号',
    IptRole: '请选择角色',
    Biggest: '查看最大化图片'
  },
  Tip: {
    imageLimit: '上传图片只能是 JPG、JPEG、PNG 格式',
    CopySuccessful: '复制成功',
    CopyFailed: '复制失败',
    Blank: '账号或密码不能为空',
    Modified: '修改密码成功',
    Digits: '密码长度不能小于6位',
    Inconsistent: '重设密码和确认密码不一致',
    equipmentExceed: '设备名称字数不能超过20',
    modelExceed: '型号名称字数不能超过20',
    Format: '手机号码格式输入错误',
    IncorrectEmail: '邮箱输入有误',
    DeleteEquipment: '你确定要删除设备吗',
    WorkshopExceed: '车间名称字数不能超过20',
    Criteria: '请输入查询条件',
    GroupExceed: '设备组名称字数不能超过20',
    DeleteGroup: '你确定要删除设备组吗',
    DeleteUser: '你确定要删除用户吗',
    DeleteWorkshop: '你确定要删除车间吗',
    UserExceed: '用户名称字数不能超过20',
    AccountExceed: '账户名称字数不能超过20',
    AddSuccess: '新增成功',
    EditSuccess: '修改成功',
    SelectMachine: '请选择需要开启的设备',
    Tips: '提示',
    Loading: '加载中...',
    token: 'Token 已过期',

    ALHigh: '温度过高',
    ALLow: '温度过低',
    ThermocoupleOpen: '热电偶开路',
    ThermocoupleReverse: '热电偶反向',
    ThermocoupleShort: '热电偶短路',
    HeaterOpen: '加热圈开路',
    HeaterShort: '加热圈短路',
    TriacBreakdown: '温可控硅击穿',
    FuseOpen: '保险丝断路',
    LeakageCurrent: '漏电故障',
    Mixed: '感温线和加热线混接',
    Default: '默认车间',
    overDate: '开始时间不能大于结束时间'
  },
  Custom: {
    Custom: '客户',
    CustomName: '客户公司名称',
    CompanyName: '请输入公司名称',
    Account: '请输入账号',
    Company: '公司名称',
    Email: '公司邮箱',
    Order: '序号',
    AddSerial: '新增序列号',
    Serial: '序列号',
    AddCompany: '新增公司',
    EditCompany: '编辑公司',
    DeleteSerial: '你确定要删除序列号吗?',
    AccountCn: '账号名称不能带有中文字符',
    overCompany: '公司名称字数不能超过20',
    SerialIpt: '请输入序列号',
    pwd: '请输入密码',
    AdPwd: '管理员密码',
    AdPower: '管理员权限',
    Number: '设备编号',
    OutSuccess: '撤回成功',
    InSuccess: '转移成功',
    CustomSelect: '客户选择',
    Background: '管理后台',
    SelectCustom: '请选择客户',
    NotMachine: '未转移设备'
  }
}

module.exports = zhLang
